  import React, { useState, useEffect } from "react";
  import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
  import moment from 'moment';
  import { Form, Input, Button, Row, Col, Select, InputNumber, DatePicker, Modal, Radio, Tooltip } from 'antd';
  import toastr from "toastr";
  import { Autocomplete, LoadScript } from '@react-google-maps/api';
  import { JobsServiceApi, UserServiceApi } from "../../api";
  import {
    getRequest,
    postRequest,
    putRequest,
  } from "../../api/http";
  import { LoadingView } from "../common";
  import "./index.scss";
  import ControlledEditor from "./editor";
  import AddQuestionModal from "./addQuestionModal";
  import UpdateQuestionModal from "./updateQuestionModal";
  import ViewQuestion from "./viewQuestion";
  import DeleteQuestion from "./deleteQuestion";
  import Questions from "./questions";
  import 'intro.js/introjs.css';
  import { InfoCircleOutlined, MinusCircleOutlined, PercentageOutlined, PlusOutlined } from '@ant-design/icons';
  import employersServiceApi from "../../api/employersServiceApi";
import AiModal from "./aiModal";
import AddQuestionModalAi from "./addQuestionModalAi";


  const JobsDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { jobId } = useParams();
    const { Option } = Select;
    const userPlan = JSON.parse(localStorage.getItem('user'))?.planName
    const noAssessmentPlanType = ["Plus", "Free Plan", "Basic","Custom Plan without Assessment"]
    const [loading, setloading] = useState(true)
    const [selectedJob, setSelectedJob] = useState({});
    const [editorHtml, setEditorHtml] = useState('');
    const [jobsCategories, setJobsCategories] = useState([]);
    const [selectedJobsCategories, setSelectedJobsCategories] = useState([]);
    const [jobStatus, setJobStatus] = useState(null);
    const inputNumberOnly = ["e", "E", "+", "-", "."];
    const [isBtnLoading, setBtnLoading] = useState(false)
    const [compensation, setCompensation] = useState('/ Month')
    const [workingHour, setWorkinHour] = useState('/ Day')
    const [addQuestionModal, setAddQuestionModal] = useState(false)
    const [updateQuestionModal, setUpdateQuestionModal] = useState(false)
    const [questionData, setQuestionData] = useState([])
    const [questionEditData, setQuestionEditData] = useState({})
    const [questionDeleteData, setQuestionDeleteData] = useState({})
    const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)
    const [viewQuestionModal, setViewQuestionModal] = useState(false)
    const [planUpgradeModal, setPlanUpgradeModal] = useState(false)
    const [showEventDropdown, setShowEventDropdown] = useState(false);
    const [eventList, setEventList] = useState([])
    const [eventEndDate, setEventEndDate] = useState(null);
    const [isEventSelected, setIsEventSelected] = useState(false);
    const [eventEndDateExists, setEventEndDateExists] = useState(false);
    const [searchText, setSearchText] = useState(selectedJob.locationName || '');
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [searchResult ,setSearchResult]=useState(null);
    const [aiModalVisible, setAiModalVisible] = useState(false);
    const [combinedDescription, setCombinedDescription] = useState('');
    const [addQuestionModalVisible,setAddQuestionModalVisible]=useState(false)
    const handleGenerateAI = () => {
      setAiModalVisible(true);
    };
    const handleGenerateQuestion = () => {
      if (noAssessmentPlanType.includes(userPlan)) {
        setPlanUpgradeModal(!planUpgradeModal)
      } else {
        setAddQuestionModalVisible(!addQuestionModalVisible);
      }
    };
    const handleGenerateQuestionAI = async (values) => {
      setQuestionData((prevData) => {
        return [...prevData, ...values];
      });
    };
  
    const handleAiSubmit = async(aiDescription) => {
      setCombinedDescription(aiDescription);
    };
    useEffect(() => {
      if (selectedJob && selectedJob.eventId) {
        setIsEventSelected(true)
        setEventEndDateExists(true)
      } else {
        setIsEventSelected(false);
        setEventEndDateExists(false)
      }
    }, [selectedJob]);
    const handlePlaceSelected = (place) => {
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setSelectedPlace({ ...place, location });

      setSearchText(place.formatted_address);
    };
    const onPlaceChanged=()=> {
      if (searchResult != null) {
        const place = searchResult.getPlace();
        handlePlaceSelected(place)

      } else {
        alert("Please enter text");
      }
    }
    // let submitStatus = '';
    const fetchJobById = async (id) => {
      try {
        const response = await getRequest(JobsServiceApi.getJobsById(id));
        setSelectedJob(response);
        setSearchText(response?.formattedAddress);
        (response.categories || []).map(item => (
          setSelectedJobsCategories(selectedJobsCategories => [...selectedJobsCategories, item.name])
        ))
        response?.showIn === 'EVENT' ? setShowEventDropdown(true) : setShowEventDropdown(false)
        setloading(false)
      } catch (e) {
        toastr.error(e?.message);
      }
    };

    const handleEventChange = (eventId) => {
      setIsEventSelected(true)
      const selectedEvent = eventList.find((event) => event.id === eventId);
      if (selectedEvent) {
        const eventEndDate = selectedEvent.endDate;
        setEventEndDate(eventEndDate); 
        setEventEndDateExists(true)

        form.setFieldsValue({
          startDate: null, 
          deadLine: null, 
        });
      } else {
        setEventEndDateExists(false)
      }
    };

    const fetchQuestionByJob = async (id) => {
      try {
        const response = await getRequest(JobsServiceApi.getJobQuestion(id));
        if (response) {
          setQuestionData(response)
          setloading(false)
        }
      } catch (e) {
        setQuestionData([])
        setloading(false)
      }
    };

    const sendChildToParent = (dataFormChild) => {
      setEditorHtml(dataFormChild)
    };

    const fetchAllJobsCategories = async () => {
      try {
        const response = await getRequest(UserServiceApi.getAllJobsCategories());
        setJobsCategories(response);
      } catch (e) {
        toastr.error(e.error.message);
      }
    };
    const fetchEvents = async () => {
      try {
        setBtnLoading(true);
        const response = await getRequest(employersServiceApi.getAllApprovedEvents());
        setEventList(response);

      } catch (e) {

        Modal.error({
          title: "Error",
          content: e.response?.data?.message || "Something went wrong...",
        });
      } finally {
        setBtnLoading(false);
      }
    };




    useEffect(async () => {
      fetchEvents()
      if (jobId) {
        await fetchJobById(jobId);
        await fetchQuestionByJob(jobId)
      } else if (location.pathname === "/jobs/duplicate") {
        await fetchJobById(location.state.jobIdForDuplicate);
        await fetchQuestionByJob(location.state.jobIdForDuplicate)
      } else {
        setloading(false)
      }
      fetchAllJobsCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);


    const OnClickPublished = (data) => {
      // submitStatus = data;
      setJobStatus(data);
      form.submit();
    };
    const OnClickOnHold = (data) => {
      // submitStatus = data;
      setJobStatus(data);
      form.submit();
    };
    const OnClickDraft = (data) => {
      // submitStatus = data;
      setJobStatus(data);
      form.submit();
    };

    const updateJobs = async (values) => {
      let categoriesValues = []
      for (const categories of values?.categories) {
        const categoriesFilter = jobsCategories.filter(
          (val) => val.name.includes(categories)).map(res => res.id);
        categoriesValues.push(...categoriesFilter)
      }
      if (editorHtml === '<p></p>') {
        toastr.error('description cant be empty');
      } 
      Modal.confirm({
        title: 'Confirm Update',
        content: 'Application end date will not be modified later. Would you like to go with this date?',
        okText:'Yes',
        cancelText:'No',
        onOk: async () => {
          const description = editorHtml;
          const status = jobStatus;
          const data = {
            ...values,
            description,
            status,
            categories: categoriesValues,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
            deadLine: values.deadLine ? moment(values.deadLine).format('YYYY-MM-DD') : null,
            jobAssessmentQuestionsInput: questionData,
            eventId: values?.eventId,
            jobType: values?.jobType,
            showIn: values?.showIn || "GENERAL",
            formattedAddressOne: selectedPlace?.formatted_address ? selectedPlace?.formatted_address : selectedJob.formattedAddress,
            locationName: selectedPlace?.name ? selectedPlace?.name : selectedJob.locationName,
            latitude: selectedPlace?.location?.lat ? selectedPlace?.location?.lat : selectedJob.location?.lat,
            longitude: selectedPlace?.location?.lng ? selectedPlace?.location?.lng : selectedJob.location?.lng,
          };

          setBtnLoading(true);
          try {
            if (jobId) {
              const response = await putRequest(JobsServiceApi.updateJobsById(jobId), data);
              if (response.status === 'PUBLISH') {
                toastr.success("Job Published Successfully");
              } else if (response.status === 'ON_HOLD') {
                toastr.success("The job has been put on hold. You can come back and publish it anytime");
              } else if (response.status === 'ARCHIVE') {
                toastr.success("The job has been successfully archived.");
              } else if (response.status === 'DRAFT') {
                toastr.success("The job has been saved in draft. You can return and publish it at any time");
              }
              navigate(-1);
            } else {
              const response = await postRequest(JobsServiceApi.addJobs(), data);
              if (response) {
                toastr.success('Saved Successfully');
                navigate(-1);
              }
            }
          } catch (error) {
            toastr.error(error.message);
          } finally {
            setBtnLoading(false);
          }
        },
        onCancel() {
          console.log('Update canceled');
        },
      });
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    function disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    }

    function disabledJobEndDate(current) {
      let startDate = form.getFieldValue('startDate')
      let beforeStartDate = moment(startDate).subtract(1, "days")
      return startDate ? current && current < moment(beforeStartDate).endOf('day') : current && current < moment().endOf('day');
    }

    function disabledApplicationEndDate(current) {
      const startDate = form.getFieldValue('startDate');
      const startDateFormat = moment(startDate, 'YYYY-MM-DD');
      // const eventEndDateFormat = moment(eventEndDate, 'YYYY-MM-DD')
      if (startDateFormat) {
        // If event end date is not available but start date is, use the existing condition for start date
        return current < moment() || current >= moment(startDateFormat, "DD-MM-YYYY").add(1, "days");
      } else {
        // If neither event end date nor start date is available, disable dates before yesterday or after today
        return current < moment().subtract(1, "days") || current >= moment();
      }
    }

    useEffect(() => {

      if (selectedJob && jobId) {
        const types = selectedJob.compensationType === 'HOURLY' ? '/ Hour' : selectedJob.compensationType === 'MONTHLY' ? '/ Month' : selectedJob.compensationType === 'PROJECT_WISE' ? '/ Project' : '';
        const hour = selectedJob.compensationType === 'HOURLY' ? '/Week' :selectedJob.compensationType === 'MONTHLY' ? '/ Day' : selectedJob.compensationType === 'PROJECT_WISE' ? '/ Project' : '';
        setCompensation(types)
        setWorkinHour(hour)
      }
    }, [jobId, selectedJob])

    if (loading) return <LoadingView message="Loading..." />;

    const cancelQuestionModal = () => {
      setAddQuestionModal(!addQuestionModal)
    }

    const handleAddAssessment = () => {
      if (noAssessmentPlanType.includes(userPlan)) {
        setPlanUpgradeModal(!planUpgradeModal)
      } else {
        setAddQuestionModal(!addQuestionModal)
      }
    }
    const handleJobShowInChange = (e) => {

      const selectedValue = e.target.value;
      if (selectedValue === "EVENT") {
        // fetchEvents()
        form.setFieldsValue({ startDate: null, deadLine: null });
        setShowEventDropdown(true);
      } else {
        form.setFieldsValue({ startDate: null });
        setIsEventSelected(false)
        setShowEventDropdown(false);
      }
    };

    const SubscriptionPlanModal = () => {
      return <Modal
        title={false}
        visible={planUpgradeModal}
        onOk={() => setPlanUpgradeModal(!planUpgradeModal)}
        onCancel={() => setPlanUpgradeModal(!planUpgradeModal)}
        footer={null}>
        <div className="subscription-upgrade-modal">
          <div className="subscription-upgrade-modal-text">
            Currently you are in {userPlan === "Free Plan" ? "Free Plan" : userPlan === "Basic" ? "Basic Plan" : userPlan === "Plus" ? "Plus Plan" : "Custom Plan"},
            <br />
            Do you want to <Link to={"/subscription"}>UPGRADE</Link> your plan?
          </div>
          <div className="subscription-upgrade-modal-btn">
            <Button
              type="primary submit"
              htmlType="submit"
              className="upgrade-btn"
              onClick={() => navigate("/subscription")}
            >
              Upgrade
            </Button>
            <Button type="secondary submit"
              htmlType="submit"
              onClick={() => setPlanUpgradeModal(!planUpgradeModal)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    }


    const handleDeleteAllQuestion = () => {
      setQuestionDeleteData({})
      setDeleteQuestionModal(!deleteQuestionModal)
    }

    return (
      <div className="container">
        <div className="headingContainer">
          <div className="headingTitle">
            <h3>
              {" "}
              {jobId
                ? selectedJob.title
                : location.pathname === "/jobs/duplicate"
                ? "Duplicate Job"
                : "Create New Job"}
            </h3>
          </div>
          <div className="headingButton">
            <Button
              className="btn ant-btn ant-btn-primary backBtn"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
          {console.log(
            "first",
            selectedJob,
            selectedJob.locationName,
            selectedJob.location
          )}
        </div>
        <div className="jobPostForm">
          <Form
            form={form}
            name="basic"
            initialValues={
              jobId
                ? {
                    title: selectedJob.title,
                    categories: selectedJobsCategories,
                    skills: selectedJob.skills,
                    workingType: selectedJob.workingType,
                    jobType: selectedJob.type,
                    workingHours: selectedJob.workingHours,
                    compensationType: selectedJob.compensationType,
                    compensation: selectedJob.compensation,
                    experienceLevel: selectedJob.experienceLevel,
                    openPositions: selectedJob.openPositions,
                    startDate:
                      selectedJob.startDate !== null
                        ? moment(selectedJob.startDate)
                        : null,
                    endDate:
                      selectedJob.endDate !== null
                        ? moment(selectedJob.endDate)
                        : null,
                    deadLine:
                      selectedJob.deadLine !== null
                        ? moment(selectedJob.deadLine)
                        : null,
                    // area: selectedJob.area,
                    // pincode: selectedJob.pincode,
                    location: selectedJob.location
                      ? selectedJob.locationName
                      : "",
                    languages: selectedJob.languages,
                    jobUrgency: selectedJob.jobUrgency,
                    country: selectedJob.country,
                    state: selectedJob.state,
                    city: selectedJob.city,
                    assessmentPassingPercentage:
                      selectedJob.assessmentPassingPercentage,
                    eventId: selectedJob.eventId,
                    showIn: selectedJob.showIn || "GENERAL",
                    preferredGender: selectedJob.preferredGender,
                  }
                : location.pathname === "/jobs/duplicate"
                ? {
                    title: selectedJob.title,
                    categories: selectedJobsCategories,
                    skills: selectedJob.skills,
                    workingType: selectedJob.workingType,
                    jobType: selectedJob.type,
                    workingHours: selectedJob.workingHours,
                    compensationType: selectedJob.compensationType,
                    compensation: selectedJob.compensation,
                    experienceLevel: selectedJob.experienceLevel,
                    openPositions: selectedJob.openPositions,
                    startDate:
                      selectedJob.startDate !== null
                        ? moment(selectedJob.startDate)
                        : null,
                    endDate:
                      selectedJob.endDate !== null
                        ? moment(selectedJob.endDate)
                        : null,
                    deadLine:
                      selectedJob.deadLine !== null
                        ? moment(selectedJob.deadLine)
                        : null,
                    // area: selectedJob.area,
                    // pincode: selectedJob.pincode,
                    location: selectedJob.locationName || "",
                    languages: selectedJob.languages,
                    jobUrgency: selectedJob.jobUrgency,
                    assessmentPassingPercentage:
                      selectedJob?.assessmentPassingPercentage,
                    eventId: selectedJob.eventId,
                    showIn: selectedJob?.showIn || "GENERAL",
                    preferredGender: selectedJob.preferredGender
                  }
                : { compensationType: "MONTHLY", assessmentPassingPercentage: 15 }
            }
            onFinish={updateJobs}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                id="jobdescription"
              >
                <div className="highLightStraps">Job details</div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: "Please enter title" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                // name="description"
                style={{marginBottom:'5px'}}
                >
                  <div className="ant-col ant-form-item-label" >
                    <label
                      htmlFor="description"
                      className="ant-form-item-required"
                      title="Job Description"
                    >
                      Job Description
                    </label>
                  </div>
                  <ControlledEditor
                    sendChildToParent={sendChildToParent}
                    description={combinedDescription? combinedDescription: jobId ? selectedJob?.description : (location.pathname === "/jobs/duplicate") ? selectedJob?.description : "<p></p>"} 
                  />
                </Form.Item>
              </Col>
                <div className="generate-ai">
                  <Button className="ai-button" onClick={handleGenerateAI}>Generate through AI</Button>
                </div>
            {eventList.length > 0 && (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item
                    label="Job Show In"
                    name="showIn"
                    initialValue="GENERAL"
                  >
                    <Radio.Group onChange={handleJobShowInChange}>
                      <Radio value="GENERAL">General</Radio>
                      <Radio value="EVENT">Event</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </>
            )}

            {showEventDropdown && (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <div className="highLightStraps">Event Selection</div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item
                    label="Select Event"
                    name="eventId"
                    rules={[
                      { required: true, message: "Please select an event" },
                    ]}
                  >
                    <Select
                      placeholder="Select an event"
                      onChange={handleEventChange}
                    >
                      {eventList.length > 0 ? (
                        eventList.map((event) => (
                          <Select.Option key={event.id} value={event.id}>
                            {event.name}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option
                          key="no-events"
                          value="no-events"
                          disabled={eventList.length === 0}
                        >
                          No approved events
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    rules={
                      jobStatus !== "DRAFT"
                        ? [{ required: true, message: "Please enter job type" }]
                        : [{ required: false }]
                    }
                  >
                    <Select placeholder="Please select Job type" allowClear>
                      <Option value="PART_TIME">Part Time</Option>
                      <Option value="FULL_TIME">Full Time</Option>
                      <Option value="INTERNSHIP">Internship</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              <div className="highLightStraps">Category</div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                label="Categories"
                name="categories"
                rules={
                  jobStatus !== "DRAFT"
                    ? [{ required: true, message: "Please enter category" }]
                    : [{ required: false }]
                }
              >
                <Select
                  placeholder="Please select category"
                  allowClear
                  mode="multiple"
                >
                  {(jobsCategories || []).map((cat) => (
                    <Option value={cat.name}>{cat.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 24 }}
              id="skills"
            >
              <div className="highLightStraps">Skills</div>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="skills"
                label="Skills"
                rules={[{ required: true }]}
              >
                <Form.List
                  name="skills"
                  label="skills"
                  // rules={jobStatus !== 'DRAFT' ? (
                  //   [
                  //     {
                  //       validator: async (_, names) => {
                  //         if (!names || names.length < 1) {
                  //           return Promise.reject(new Error('At least add 1 skills'));
                  //         }
                  //       },
                  //     },
                  //   ]
                  // ) : ([{ required: false }])}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <React.Fragment>
                      {fields.map((field, index) => (
                        <Form.Item
                          // label={index === 0 ? 'Skills' : ''}
                          required={false}
                          key={field.key}
                          className="skillsBox"
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={
                              jobStatus !== "DRAFT"
                                ? [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please add skills.",
                                    },
                                  ]
                                : [{ required: false }]
                            }
                            noStyle
                          >
                            <Input
                              placeholder="Enter Skills"
                              style={{ width: "90%", marginRight: "5px" }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%", marginTop: "20px" }}
                          icon={<PlusOutlined />}
                        >
                          Add Skills
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </React.Fragment>
                  )}
                </Form.List>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 24 }}
              id="basicinfo"
            >
              <div className="highLightStraps">Basic Information</div>
            </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                <Form.Item
                  label="Preferred Gender"
                  name="preferredGender"
                  initialValue="ALL"
                  rules={[
                    { required: true, message: "Please select preferred gender" }
                  ]}
                >
                  <Select placeholder="Select preferred gender">
                    <Option value="ALL">All</Option>
                    <Option value="MALE">Male</Option>
                    <Option value="FEMALE">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Working Type"
                name="workingType"
                rules={
                  jobStatus !== "DRAFT"
                    ? [{ required: true, message: "Please enter working type" }]
                    : [{ required: false }]
                }
              >
                <Select placeholder="Please select working type" allowClear>
                  <Option value="WORK_FROM_OFFICE">Work From Office</Option>
                  <Option value="WORK_FROM_HOME">Work From Home</Option>
                  <Option value="FLEXIBLE">Flexible</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Compensation Type"
                name="compensationType"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          required: true,
                          message: "Please enter compensation type",
                        },
                        () => ({
                          validator(_, value) {
                            if (value) {
                              const types =
                                value === "HOURLY"
                                  ? "/ Hour"
                                  : value === "MONTHLY"
                                  ? "/ Month"
                                  : value === "PROJECT_WISE"
                                  ? "/ Project"
                                  : "";
                              const hour =
                              value === "HOURLY"
                                  ? "/Week" :
                                value === "MONTHLY"
                                  ? "/ Day"
                                  : value === "PROJECT_WISE"
                                  ? "/ Project"
                                  : "";
                              setCompensation(types);
                              setWorkinHour(hour);
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]
                    : [{ required: false }]
                }
              >
                <Select
                  placeholder="Please select compensation"
                  defaultValue="MONTHLY"
                  allowClear
                >
                  <Option value="HOURLY">Hourly</Option>
                  <Option value="MONTHLY">Monthly</Option>
                  <Option value="PROJECT_WISE">Project Wise</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
               label={
                <span>
                  Working Hours {workingHour}{' '}
                  <Tooltip title="Event/project based jobs: No hourly limits.
Regular jobs: Max 20 hrs/week or 4 hrs/day.">
                    <InfoCircleOutlined style={{ color: '#ed9f0b' ,fontSize:"15px" ,marginLeft:'4px' }} />
                  </Tooltip>
                </span>
              }
                name="workingHours"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          required: true,
                          message: "Please enter working hours",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const compensationType = getFieldValue('compensationType');
                            
                            if (!showEventDropdown && value) {
                              if (compensationType === 'HOURLY' && value > 20) {
                                return Promise.reject('Working hours cannot exceed 20 hours per week for hourly compensation');
                              }
                              if (compensationType === 'MONTHLY' && value > 4) {
                                return Promise.reject('Working hours cannot exceed 4 hours per day for monthly compensation');
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]
                    : [{ required: false }]
                }
              >
                <InputNumber
                  type="number"
                  min={1}
                  style={{ width: "100%" }}
                  onKeyDown={(e) =>
                    inputNumberOnly.includes(e.key) && e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label={`Compensation ${compensation}`}
                name="compensation"
                rules={
                  jobStatus !== "DRAFT"
                    ? [{ required: true, message: "Please enter compensation" }]
                    : [{ required: false }]
                }
              >
                <Input
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  onKeyDown={(e) =>
                    inputNumberOnly.includes(e.key) && e.preventDefault()
                  }
                  prefix={
                    <span
                      style={{
                        fontFamily: '"cursive", "Segoe UI","sans-serif"',
                      }}
                    >
                      &#x20B9;
                    </span>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Experience"
                name="experienceLevel"
                rules={
                  jobStatus !== "DRAFT"
                    ? [{ required: true, message: "Please enter experience" }]
                    : [{ required: false }]
                }
              >
                <Select placeholder="Please select experience" allowClear>
                  <Option value="FRESHER">Fresher</Option>
                  <Option value="EXPERIENCED">Experienced</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Open Positions"
                name="openPositions"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          required: true,
                          message: "Please enter Open positions",
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <InputNumber
                  type="number"
                  min={1}
                  style={{ width: "100%" }}
                  onKeyDown={(e) =>
                    inputNumberOnly.includes(e.key) && e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Job Start Date"
                name="startDate"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          required: true,
                          message: "Please enter job start date",
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Job End Date"
                name="endDate"
                dependencies={["startDate"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      let validStartDate = getFieldValue("startDate");
                      if (validStartDate && value) {
                        if (validStartDate <= value) {
                          return Promise.resolve();
                        } else {
                          if (
                            moment(value).format("DD-MM-YYYY") !==
                            moment(validStartDate).format("DD-MM-YYYY")
                          ) {
                            return form.setFields([
                              { name: "endDate", value: "", errors: "" },
                            ]);
                          } else {
                            return Promise.resolve();
                          }
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  disabledDate={disabledJobEndDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Application End Date"
                name="deadLine"
                // dependencies={['startDate']}
                rules={
                  jobStatus !== "DRAFT" && !eventEndDateExists
                    ? [
                        {
                          required: true,
                          message: "Please enter application end date",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let validStartDate = getFieldValue("startDate");
                            if (validStartDate) {
                              if (validStartDate >= value) {
                                return Promise.resolve();
                              } else {
                                if (
                                  moment(value).format("DD-MM-YYYY") !==
                                  moment(validStartDate).format("DD-MM-YYYY")
                                ) {
                                  return form.setFields([
                                    { name: "deadLine", value: "", errors: "" },
                                  ]);
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          },
                        }),
                      ]
                    : [{ required: false }]
                }
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  disabled={isEventSelected}
                  disabledDate={disabledApplicationEndDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Area"
                name="area"
                rules={jobStatus !== 'DRAFT' ? ([{ required: true, message: 'Please enter area' }]) : ([{ required: false }])}
              >
                <Input />
              </Form.Item>

            </Col> */}
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Location"
                name="location"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          required: true,
                          message: "Please select a location",
                          validator: async (_, value) => {
                            if (!value && !searchText) {
                              return Promise.reject(
                                new Error("Please select a location")
                              );
                            }
                          },
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <LoadScript
                  googleMapsApiKey="AIzaSyDbk_crVlsw_Hq_COkRaGb2N8V7Ay11ULo"
                  libraries={["places"]}
                >
                  <Autocomplete
                    onLoad={(autocomplete) => {
                      setSearchResult(autocomplete);
                      // console.log(autocomplete,'isnot')
                      // autocompleteInstance = autocomplete; // Assign the Autocomplete instance to the variable
                      // autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
                    }}
                    onPlaceChanged={onPlaceChanged}
                    restrictions={{ country: "IN" }} // Set restrictions to India
                    // bounds={{ east: 97.3, west: 68.7, north: 37.6, south: 8.4 }}
                  >
                    <input
                      type="text"
                      className="location-input"
                      placeholder="Search for a place"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Autocomplete>
                </LoadScript>
              </Form.Item>
            </Col>

            {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                label="Pin code"
                name="pincode"
                rules={jobStatus !== 'DRAFT' ? ([{ required: true, message: 'Please enter pincode' }]) : ([{ required: false }])}
              >
                <InputNumber type="number" min={1} style={{ width: '100%' }} />
              </Form.Item>
            </Col> */}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 24 }}
              id="language"
            >
              <div className="highLightStraps">Language</div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              <Form.List
                name="languages"
                label="languages"
                rules={
                  jobStatus !== "DRAFT"
                    ? [
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              return Promise.reject(
                                new Error("At least add 1 Language")
                              );
                            }
                          },
                        },
                      ]
                    : [{ required: false }]
                }
              >
                {(fields, { add, remove }, { errors }) => (
                  <React.Fragment>
                    {fields.map((field, index) => (
                      <Form.Item
                        // label={index === 0 ? 'Skills' : ''}
                        required={false}
                        key={field.key}
                        className="skillsBox"
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={
                            jobStatus !== "DRAFT"
                              ? [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please add language.",
                                  },
                                ]
                              : [{ required: false }]
                          }
                          noStyle
                        >
                          <Input
                            placeholder="Enter Language"
                            style={{ width: "90%", marginRight: "5px" }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "100%", marginTop: "20px" }}
                        icon={<PlusOutlined />}
                      >
                        Add Language
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 24 }}
              id="joburgency"
            >
              <div className="highLightStraps">Job Urgency</div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item label="Select Urgency" name="jobUrgency">
                <Select placeholder="Please select urgency type" allowClear>
                  <Option value="HIGH">High</Option>
                  <Option value="MEDIUM">Medium</Option>
                  <Option value="LOW">Low</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 24 }}
              id="assesment"
            >
              <div className="highLightStraps">Assessment</div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              {questionData?.length !== 0 ? (
                <Questions
                  questionData={questionData}
                  setQuestionEditData={setQuestionEditData}
                  setQuestionDeleteData={setQuestionDeleteData}
                  deleteQuestionModal={deleteQuestionModal}
                  setDeleteQuestionModal={setDeleteQuestionModal}
                  viewQuestionModal={viewQuestionModal}
                  setViewQuestionModal={setViewQuestionModal}
                />
              ) : null}
              <div className="question-info-section">
                <div className="question-message-limit">
                  <InfoCircleOutlined className="question-note-icon" /> You are
                  allowed to add max 10 questions per assesment.
                </div>
                {questionData?.length > 1 ? (
                  <div className="question-delete-all-btn">
                    If you want to delete all the Questions?{" "}
                    <Button onClick={handleDeleteAllQuestion} type="primary">
                      Delete All
                    </Button>
                  </div>
                ) : null}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={handleAddAssessment}
                  style={{ width: "100%", marginTop: "20px" }}
                  icon={<PlusOutlined />}
                  disabled={questionData?.length >= 10 ? true : false}
                >
                  Add Questions
                </Button>
              </Form.Item>
            </Col>
            <div className="generate-ai" style={{margin:'5px'}}>
              <Button className="ai-button" onClick={handleGenerateQuestion} disabled={questionData?.length >= 10 ? true : false}>Get AI generated Questionnaire</Button>
             </div>
            {questionData?.length >= 1 ? (
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                <Form.Item
                  label="Passing Percetage"
                  name="assessmentPassingPercentage"
                  rules={[
                    {
                      required: true,
                      message: "Please enter passing percentage",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    defaultValue={15}
                    min={1}
                    max={100}
                    addonAfter={<PercentageOutlined />}
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Form.Item>
            {!isBtnLoading ? (
              <React.Fragment>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => OnClickPublished("PUBLISH")}
                >
                  Publish
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => OnClickOnHold("ON_HOLD")}
                >
                  On Hold
                </Button>
                {
                  //<Button type="primary" htmlType="button" onClick={() => OnClickArchive('ARCHIVE')}>Archive</Button>
                }
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => OnClickDraft("DRAFT")}
                >
                  Draft
                </Button>
              </React.Fragment>
            ) : (
              <Button type="primary" htmlType="button" loading={isBtnLoading}>
                Loading...
              </Button>
            )}
          </Form.Item>
        </Form>
        <ViewQuestion
          viewQuestionModal={viewQuestionModal}
          setViewQuestionModal={setViewQuestionModal}
          questionEditData={questionEditData}
          updateQuestionModal={updateQuestionModal}
          setUpdateQuestionModal={setUpdateQuestionModal}
          setQuestionEditData={setQuestionEditData}
        />
        <AddQuestionModal
          setQuestionEditData={setQuestionEditData}
          questionEditData={questionEditData}
          addQuestionModal={addQuestionModal}
          cancelQuestionModal={cancelQuestionModal}
          setQuestionData={setQuestionData}
          questionData={questionData}
          fetchQuestionByJob={fetchQuestionByJob}
        />
        <UpdateQuestionModal
          updateQuestionModal={updateQuestionModal}
          setUpdateQuestionModal={setUpdateQuestionModal}
          setQuestionEditData={setQuestionEditData}
          questionEditData={questionEditData}
          setQuestionData={setQuestionData}
          questionData={questionData}
          fetchQuestionByJob={fetchQuestionByJob}
        />
        <DeleteQuestion
          questionDeleteData={questionDeleteData}
          deleteQuestionModal={deleteQuestionModal}
          setDeleteQuestionModal={setDeleteQuestionModal}
          questionData={questionData}
          setQuestionData={setQuestionData}
          fetchQuestionByJob={fetchQuestionByJob}
        />
        <AiModal
          visible={aiModalVisible}
          onClose={() => setAiModalVisible(false)}
          onSubmit={handleAiSubmit}
          initialValues={form.getFieldsValue()}
          setloading={setloading}
      />
      <AddQuestionModalAi
       visible={addQuestionModalVisible}
       onClose={() => setAddQuestionModalVisible(false)}
       questionData={questionData}
       onSubmit={handleGenerateQuestionAI}
       initialValues={form.getFieldsValue()}
       setloading={setloading}
      />
        {planUpgradeModal ? SubscriptionPlanModal() : null}
      </div>
    </div>
  );
};

export default JobsDetails;
