import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from 'antd';
import toastr from 'toastr';
import { JobsServiceApi } from '../../api';
import { getRequest } from '../../api/http';
import { LoadingView } from '../common';
import JobCard from '../jobCard';
import './index.scss';
import moment from 'moment';

export default function JobPost() {

  const navigate = useNavigate();
  const { jobId } = useParams();
  const [loading, setloading] = useState(true)
  const [selectedJob, setSelectedJob] = useState({});
  const [currentJob, setCurrentJob] = useState([])

  console.log("currentJob", currentJob);
  
  const fetchJobById = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await getRequest(JobsServiceApi.getJobsById(jobId));
      setSelectedJob(response);
      setloading(false)
    } catch (e) {
      toastr.error(e.message);
    }
  };

  useEffect(() => {
    if (jobId) {
      fetchJobById();
    } else {
      setloading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const textUpperCase = (val) => {
    return val.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replace(/_/g, " ");
  }

  const renderJobOfferedInfo = () => {
    return (
      <JobCard jobId={jobId} setCurrentJob={setCurrentJob}/>
    )
  }

  const renderJobsList = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div className='jobDetailsContent'>
        <div className="">
          {renderJobOfferedInfo()}
        </div>
        <div className='JPVDescription'>
          <h3>Job Details</h3>
          <div
            className='descriptionHtml'
            dangerouslySetInnerHTML={{ __html: selectedJob.description }}
          />
        </div>
        <div className='JPVOtherInfo fullRow'>
          <h3>Category</h3>
          <ul>
            <li className='languagesBox'>
              <span>Category</span>
              {
                (selectedJob.categories).length === 0 ? (
                  <span>-</span>
                ) : (
                  <span>{(selectedJob.categories || []).map((cat, i) =>
                    <React.Fragment key={i}>
                      <span className='LangName'>{cat.name}</span><span className='comma'>, &nbsp;</span>
                    </React.Fragment>)}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
        <div className='JPVOtherInfo fullRow'>
          <h3>Skills</h3>
          <ul>
            <li className='languagesBox'>
              <span>Skills</span>
              {
                (selectedJob.skills).length === 0 ? (
                  <span>-</span>
                ) : (
                  <span>{(selectedJob.skills || []).map((sk, i) =>
                    <React.Fragment key={i}>
                      <span className='LangName'>{sk}</span><span className='comma'>, &nbsp;</span>
                    </React.Fragment>)}
                  </span>
                )
              }

            </li>
          </ul>
        </div>

        <div className='JPVOtherInfo'>
          <h3>Basic Information</h3>
          <ul>
            <li><span>Working Type</span><span>{selectedJob.workingType !== null ? textUpperCase(selectedJob.workingType) : '-'}</span></li>
            <li><span>Working Hours</span><span>{selectedJob.workingHours !== null ? selectedJob.workingHours : '-'} {selectedJob.compensationType === 'MONTHLY' ? '/ Day' : selectedJob.compensationType === 'PROJECT_WISE' ? '/ Project' : ''}</span></li>
            <li><span>Compensation Type</span><span>{selectedJob.compensationType !== null ? textUpperCase(selectedJob.compensationType) : '-'}</span></li>
            <li><span>Compensation</span>
              <span style={{ fontFamily: '"cursive", "Segoe UI","sans-serif"' }}
              >
                &#x20B9; {selectedJob.compensation !== null ? selectedJob.compensation : '-'} / {selectedJob.compensationType === 'HOURLY' ? 'Hour' : selectedJob.compensationType === 'MONTHLY' ? 'Month' : selectedJob.compensationType === 'PROJECT_WISE' ? 'Project' : '--'}
              </span>
            </li>
            <li><span>Experience</span><span>{selectedJob.experienceLevel !== null ? textUpperCase(selectedJob.experienceLevel) : '-'}</span></li>
            <li><span>Open Positions</span><span>{selectedJob.openPositions !== null ? selectedJob.openPositions : '-'}</span></li>
            <li><span>Start Date</span><span>{selectedJob.startDate !== null ? moment(selectedJob.startDate).format('DD-MM-YYYY') : '-'}</span></li>
            <li><span>End Date</span><span>{selectedJob.endDate !== null ? moment(selectedJob.endDate).format('DD-MM-YYYY') : '-'}</span></li>
            <li><span>Application End Date</span><span>{selectedJob.deadLine !== null ? moment(selectedJob.deadLine).format('DD-MM-YYYY') : '-'}</span></li>
            <li><span>Area</span><span>{selectedJob.area !== null ? selectedJob.area : '-'}</span></li>
            <li><span>Pin code</span><span>{selectedJob.pincode !== null ? selectedJob.pincode : '-'}</span></li>
            <li><span>Preferred Gender</span><span>{selectedJob.preferredGender !== null ? selectedJob.preferredGender : '-'}</span></li>
          </ul>
        </div>
        <div className='JPVOtherInfo fullRow'>
          <h3>Languages</h3>
          <ul>
            <li className='languagesBox'>
              <span>Languages</span>
              {
                (selectedJob.languages).length === 0 ? (
                  <span>-</span>
                ) : (
                  <span>{(selectedJob.languages || []).map((sk, i) =>
                    <React.Fragment key={i}>
                      <span className='LangName'>{sk}</span><span className='comma'>, &nbsp;</span>
                    </React.Fragment>)}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
        <div className='JPVOtherInfo fullRow'>
          <h3>Job Urgency</h3>
          <ul>
            <li className='languagesBox'>
              <span>Job Urgency</span>
              <span>{selectedJob.jobUrgency !== null ? textUpperCase(selectedJob.jobUrgency) : '-'}</span>
            </li>
          </ul>
        </div>

        {
          // <div className='JPVOtherInfo'>
          //   <h3>Address</h3>
          //   <ul>
          //     <li><span>Area</span><span>{selectedJob.area}</span></li>
          //     <li><span>City</span><span>{selectedJob.city}</span></li>
          //     <li><span>State</span><span>{selectedJob.state}</span></li>
          //     <li><span>Country</span><span>{selectedJob.country}</span></li>
          //     <li><span>Pin code</span><span>{selectedJob.pincode}</span></li>
          //   </ul>
          // </div>
        }
      </div>
    );
  };

  return (
    <div className="container">
      <div className="headingContainer">
        <div className="headingTitle"><h3>Jobs Details</h3></div>
        <div className="headingButton">
          <Button className="btn ant-btn ant-btn-primary backBtn" onClick={() => navigate(-1)}>Back</Button>
          <Link className="btn ant-btn ant-btn-primary" to={{ pathname: `/jobs/${jobId}` }}>Edit</Link>
        </div>
      </div>
      <div className="jobPostView">
        {renderJobsList()}
      </div>
    </div>
  );
}
